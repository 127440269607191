.btns button {
  padding: 0 14px;
  height: 40px;
  border: 1px solid transparent;
  background-color: transparent;
  color: #fff;
  opacity: 0.9;
  cursor: pointer;
  font-family: 'GT-Walsheim-Regular-Trial';


}

.btns button:not(:last-of-type) {
  margin-right: 3px;
}

.btns button:hover {
  color: #ffffff !important;
}

.btns button.active {
  border: 1px solid #4B4B4B;
  border-radius: 11px;

  color: #ffffff !important;
}

.btns {
  display: flex;
  justify-content: center;
  flex-basis: 1;
  flex-wrap: wrap;

  @media screen and (max-width:750px) {
    width: 340px;
    display: flex;
    display: -webkit-flex;
    display: -moz-box;
    display: -webkit-box;
    display: -ms-flexbox;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;
  }
}

.btns button {
  padding: 0 11px;
}

.btns button:not(:last-of-type) {
  margin-right: 1px;
}

.page2 {
  box-sizing: border-box;
}

@media screen and (min-width:750px) {

  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F4F4F4;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(121, 121, 121, 0.4);
  }
}

.PartnerWrapper {
  /* 264+88 */
  margin-bottom: 352px;

  .submit {
    height: 40px;
  }
}

.btnWrapper {

  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  /* width:100%;
  display:flex;
  justify-content:center;
  position: absolute;
  bottom: -88px;
  clear: both;
  z-index: 1000; */
  /*
  @media screen and (max-width:1280px) {
    bottom:-18.3rem;

  }
  @media screen and (max-width:768px) {
    bottom:-158px;

  } */
}

.footerBox {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 400px;
  background: radial-gradient(37.62% 51.62% at 24.59% 100%, rgba(102, 0, 255, 0.303) 0%, rgba(20, 0, 255, 0.00) 100%), radial-gradient(44.8% 50.53% at 11.91% 46.14%, transparent 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(86.39% 78.28% at 97.16% 105.97%, rgba(100, 0, 255, 0.624) 0%, rgba(153, 0, 255, 0.00) 100%), radial-gradient(22.72% 111.23% at 58.81% 104.81%, rgba(102, 0, 255, 0.496) 14.5%, rgba(102, 0, 255, 0.00) 100%), transparent;

  display: flex;
  justify-self: space-between;
  align-items: flex-end;
  padding-bottom: 16px;


  .footerPicture {
    display: none;
    width: 100%;
    height: auto;
    opacity: 1;
  }

}
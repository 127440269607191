.HeaderCover {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 70px;
  z-index: 1100;
  background-color: #ffffff18;

  @media screen and (max-width:768px) {
    height: 56px;

  }


  .innerCover{
    margin: 0 auto;
    width: 1280px;
    height: 70px;
    padding: 0 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1280px) {
      width:100%;
    }

    @media screen and (max-width:768px) {
      padding: 0;
      height: 56px;
    }



    .themeLogo{
        width: 100px;
        height: 30px;

        @media screen and (max-width:768px) {
          display: none;
          width: auto;
          height: 18px;
        }

        &>img{
          width: 100%;
          height: 100%;
        }
      }

    .right{
      display: flex;
      align-items: center;

      @media screen and (max-width:768px) {
        display: none;
      }

      &>a,&>.a{
        font-size: 16px;
        font-family: 'GT-Walsheim-Regular-Trial';
        font-weight: normal;
        // color: transparent;
        line-height: 19px;
        text-decoration: none;
        cursor: pointer;
        color: #fff!important;
      }

      .linkMenu{
        color: #fff!important;

      }

      .a{
        margin-right: 24px;
      }

      &>a:not(:last-of-type){
        margin-right: 24px;
      }

      .a:hover,a:hover{
        background: linear-gradient(90deg, #47A7FF 3.8%, #47A7FF 91.66%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }




    .breadBox {
      flex:1;
      position: relative;
      display: none;

      @media screen and (max-width:768px) {
        display: block;
      }

      .bread{
        position: absolute;
        right: 0px;
        margin-left: 28px;
        width: 24px;
        height: 24px;
        // visibility: hidden;
        cursor: pointer;
      }

      .menus {
        display: none;
        position: fixed;
        top: 56px;
        right: -0px;
        left: -0px;
        z-index: 1500;
      }

      .show{
        display: block;
      }

    }
  }
}
.mobile_header{
  display: none;

  @media screen and (max-width:768px) {
    display: block;
    box-sizing: border-box;
    padding: 0 28px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .logo_mobile {
      width: auto;
      height: 18px;
    }

  }
}
.launchApp{
  border: 1px solid #fff;
  padding: 7px 10px;
  border-radius: 25px;
}
.launchApp:hover{
  color: #1d1d1d!important;
  background: #eee!important;
  -webkit-text-fill-color: #1d1d1d!important;
}
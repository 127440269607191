.Platform {
  width: fit-content;
  height: 55px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1280px) {
   height: 4.6rem;
  }

  @media screen and (max-width: 768px) {
    width: 353px;
    height: fit-content;
   flex-wrap: wrap;
   justify-content: center;
  }


  img:not(:last-of-type) {
    margin-right: 16px;


    @media screen and (max-width: 1280px) {
      margin-right: 1.5rem;
    }

    @media screen and (max-width: 768px) {
      margin-right: 16px;
    }
  }
//layerZero
.layerZeroImg {
  width: 128.331px;
  height: 34.223px;

    @media screen and (max-width: 1280px) {
      width: 10.6rem;
      height: auto;
    }

    @media screen and (max-width: 768px) {
      width: 65px;
    }
  }

  //polygon

  .polygonImg{
    width: 157.441px;
    height: 33.136px;

    @media screen and (max-width: 1280px) {
      width: 13rem;
      height: auto;
    }
    @media screen and (max-width: 768px) {
     width: 80px;
     margin-bottom: 16px;
    }
  }

  //emade
  .emadeImg {
    width: 107.236px;
    height: 30.282px;

    @media screen and (max-width: 1280px) {
      width: 8.9rem;
      height: auto;
    }
    @media screen and (max-width: 768px) {
      width: 64px;
    }
  }

  //consensys
  .consensysImg {
    width: 150px;
    height: 32.297px;

    @media screen and (max-width: 1280px) {
      width: 12.5rem;
      height: auto;
    }

    @media screen and (max-width: 768px) {
      width: 76px;
      margin-bottom: 16px;
    }
  }
//kroma
.kromaImg {
  width: 118.812px;
  height: 27.551px;

    @media screen and (max-width: 1280px) {
      width: 9.8rem;
      height: auto;
    }
    @media screen and (max-width: 768px) {
      width: 60px;
      margin-bottom: 16px;
    }
  }

  //onekey
  .onekeyImg {
    width: 99.806px;
    height: 54.835px;

    @media screen and (max-width: 1280px) {
      width: 8.3rem;
      height: auto;
    }

    @media screen and (max-width: 768px) {
      width: 51px;
      margin-bottom: 16px;
    }
  }

  //solanr
  .solanrImg {
    width: 141.588px;
    height: 21.279px;
    @media screen and (max-width: 1280px) {
      width: 11.75rem;
      height: auto;
    }

    @media screen and (max-width: 768px) {
      width: 72px;
    }
  }
}
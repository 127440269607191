.Learn {
  margin-top: 27px;


  @media screen and (max-width: 1280px) {
    margin-top:2.25rem;
  }
  @media screen and (max-width: 768px){
    margin-top: 0px;
  }

  .titleWrapperBox{
    position: relative;

    // .titleWrapper3{
    //   position: absolute;
    //   inset: 0;
    //   width: 900px;
    //   height: 247px;
    //   margin: 0 auto;

    //   background-image: url('../../assets/images/HeaderLight.svg');
    //   background-size:cover;
    //   background-position: -330px 600px;

    //   -webkit-text-fill-color: transparent;
    //   -webkit-background-clip: text;

    //  .row{
    //   height: 148px;
    //   display: flex;
    //   align-items: center;
    //  }

    //   .one3{
    //     font-size: 80px;
    //     font-family: 'GT-Walsheim-Bold-Trial';
    //     line-height: 120px;
    //     white-space:nowrap;
    //     color: #FFFFFF;
    //   }
    // }

    .titleWrapper2{
      // width: 900px;
      // height: 247px;
      position: absolute;
      inset: 0;


      background-image: url('../../assets/images/HeaderLight.svg');
      background-size:cover;
      background-position: -330px 600px;
      animation-name: sunLight;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;

      @media screen and (max-width:1280px) {
        background-position: -27.5rem 50rem;
        animation-name: sunLight_Mobile;
      }

      @media screen and (max-width:1280px) {
        background-position: -120px 430px;
        // background-position: 200px 430px;
        animation-name: sunLight_Mobile_768;
      }



    }

    @keyframes sunLight {
      from{
        background-position: -330px 600px;
      }
      to{
        background-position: 1380px 600px;
      }
    }

    @keyframes sunLight_Mobile {
      from{
        background-position: -27.5rem 50rem;
      }
      to{
        background-position: 115rem 50rem;
      }
    }

    @keyframes sunLight_Mobile_768 {
      from{
        background-position: -120px 430px;
      }
      to{
        background-position: 200px 430px;
      }
    }

  }


  .titleWrapper,.titleWrapper2 {
    width: 900px;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;



    @media screen and (max-width: 1280px) {
      width:75rem;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    p {
      //width: 531px;
      height: 148px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 1280px) {
        height:12.3rem;
      }

      @media screen and (max-width: 768px) {
        width: 292px;
        height: auto;
        margin-top: 16px;
      }
    }

    .mobile_title{
      margin-top: -10px;
    }

    .hideText{
      opacity: 0;
    }

    .one{
      // width: 539px;
      font-size: 80px;
      font-family: 'GT-Walsheim-Bold-Trial';
      line-height: 120px;
      white-space:nowrap;
      color: #FFFFFF;

      @media screen and (max-width: 1280px) {
        // width:45rem;
        font-size: 6.6rem;
        line-height: 10rem;
      }
      @media screen and (max-width: 768px) {
        // width:257px;
        line-height: 57px;
        font-size: 38px;
      }
    }

    .moonBox {
      margin: 0 20px 0 22px;
      width: 128px;
      height: 148px;
      position: relative;
      text-align: center;

      @media screen and (max-width: 1280px) {
        width:10.67rem;
        height: 12.3rem;
        // margin: 0 3.63rem 0 -1.63rem;
      }

      @media screen and (max-width: 768px){
        margin: 16px 0 0 0;
        width: 91.452px;
        height: 105.109px;
      }
    }

    .moon {
      inset: 0;
      margin: auto;
      position: relative;
      margin-left: -10px;
      opacity: 0.8;
      height: 150px;


      @media screen and (max-width: 1280px) {
        height:12.3rem;
      }

      @media screen and (max-width: 768px) {
        height: 105px;
        margin-top: 10px;
      }
    }

    .for {
      width: 180px;
      font-size: 80px;
      font-family: 'GT-Walsheim-Bold-Trial';
      line-height: 120px;
      color: #FFFFFF;
      white-space: pre-wrap;

      white-space:nowrap;
      @media screen and (max-width: 1280px) {
        width:15rem;
        font-size: 6.6rem;
        line-height: 10rem;
      }
      @media screen and (max-width: 768px) {
        width:74px;
        margin-right:14px;
        line-height: 57px;
        font-size: 38px;
      }
    }
    .chains {
      width: 415px;
      font-size: 80px;
      font-family: 'GT-Walsheim-Bold-Trial';
      line-height: 120px;
      color: #FFFFFF;
      white-space:nowrap;

      @media screen and (max-width: 1280px) {
        width:35rem;
        font-size: 6.6rem;
        line-height: 10rem;
      }
      @media screen and (max-width: 768px) {
        // width:203px;
        line-height: 57px;
        font-size: 38px;
      }
      // margin-top: -20px;
    }
  }


  .desc {
    margin-top: 30px;
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 36px;
    font-family: 'GT-Walsheim-Light-Trial';
    opacity: 0.8;

    @media screen and (max-width: 1280px) {
      font-size: 2rem;
      line-height: 3rem;
      margin-top: 1.67rem;
      margin-bottom: 2.08rem;
    }

    //
    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 21px;
      margin-top: 10px;
      margin-bottom: 37px;
    }
  }

  .btnBox {
    display: flex;
    justify-content: center;
  }

  .text{
    margin-top: 102px;
    width: 100%;
    text-align: center;
    font-family: 'GT-Walsheim-Regular-Trial';
    font-size: 16px;
    color: #FFFFFF;
    @media screen and (max-width: 1280px) {
      margin-top: 6.08rem;
      font-size: 1.3rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 32px;
      margin-top: 48px;
    }
  }


  .footer{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1280px) {
     margin-top: 1.6rem;
    }

    @media screen and (max-width: 768px) {
      margin-top: 14px;
    }
  }




}

.Menus {
  width: 100%;
  max-height: 232px;
  padding:18px 33px 4px 33px;
  //transition-property:height;
  //transition-duration:12s;

  background: rgba(11, 12, 42, 0.23);
  backdrop-filter: blur(26.200000762939453px);
  animation-name: height;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  overflow: hidden;

  @keyframes height {
    from{
      height: 0;
    }
    to{
      height: 232px;
    }

  }



  .menusInner{
    width: 100%;
    .menusItem{
      width: 100%;
      height: 18px;
      line-height: 30px;
      margin-bottom: 24px;
      // border-bottom: 1px solid rgba(255, 255, 255, 0.10);
      font-size: 16px;
      line-height: 18px;
      font-family: 'GT-Walsheim-Regular-Trial';
      color: #8F8F8F;
      cursor: pointer;

      text-align: end;

      span:hover{
        background: linear-gradient(90deg, #9847FF 3.8%, #47A7FF 91.66%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

    }

    // .menusItem:not(:last-of-type){
    //   border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    // }
  }
}
.hiddrenAnchor{
  animation-name: height2;
}

@keyframes height2 {
  from{
    height: 0;
  }
  to{
    height: 148px;
  }

}
@mixin Render {
  width: 85rem;
  height: 85rem;
  flex-shrink: 0;
  border-radius: 85rem;
  opacity: 0.68;
  // background: radial-gradient(50% 50% at 50% 50%, #41C9E2 0%, rgba(146, 107, 255, 0.00) 100%);
  -webkit-border-radius: 85rem;
  -moz-border-radius: 85rem;
  -ms-border-radius: 85rem;
  -o-border-radius: 85rem;

  @media screen and (max-width: 768px) {
    width: 561px;
    height: 561px;
  }
}

.home {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  // background: #008edad3;
  background: #00000059;
  padding-top: 70px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width:768px) {
    padding-top: 56px;
  }



  /* .allLight_mobile {
    display: none;

    @media screen and (max-width:450px) {
      display: block;
      width: 929px;
      height: 876px;
      flex-shrink: 0;
      position: absolute;
      top: 1229px;
      left: -14.8rem;
    }

    @media screen and (max-width:390px) {
      left: -269px;
    }
  } */


  .leftRenderWrapper {
    position: absolute;
    width: 100%;
    /* width: 1280px; */
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    overflow: hidden;
    z-index: 99;


    @media screen and (max-width:1280px) {
      width: 100%;
    }

    .leftRender {
      position: absolute;
      left: -53.75rem;
      top: -29.3rem;
      @include Render;

      @media screen and (max-width: 768px) {
        left: -285px;
        top: -262px;

        border-radius: 561px;
        opacity: 0.56;
      }
    }

    .rightRender {
      position: absolute;
      right: -53.75rem;
      top: -29.3rem;
      @include Render;

      @media screen and (max-width: 768px) {
        width: 452px;
        height: 452px;
        right: -242px;
        top: 0px;

        border-radius: 452px;
        opacity: 0.43;
      }
    }
  }

  .homeInner {
    width: 1280px;
    height: fit-content;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }

    .allLight {
      display: block;
      position: absolute;
      /* top: 488px;
      left: -557px;
      width: 2439px;
      height: 2430px; */
      top: 450px;

      width: 1280px;
      height: auto;

      @media screen and (max-width:1280px) and (min-width: 768px) {
        top: 37.5rem;
        width: 100%;
      }

      @media screen and (max-width:768px) {
        width: 100vw;
        top: 1019px;
      }

      @media screen and (max-width:450px) {
        top: 1219px;
      }

      /*


      @media screen and (max-width:450px) {
        display: block;
        width: 929px;
        height: 876px;
        flex-shrink: 0;
        position: absolute;
        top: 1229px;
        left: -14.8rem;
      }

      @media screen and (max-width:390px) {
        left: -269px;
      } */
    }

    /* .leftRender {
      position: absolute;
      left: -645px;
      top: -352px;
      width: 1020px;
      height: 1020px;
      flex-shrink: 0;
      border-radius: 1020px;
      opacity: 0.68;
      background: radial-gradient(50% 50% at 50% 50%, #48A7FF 0%, rgba(104, 8, 229, 0.00) 100%);
    } */
  }

  .digitalWrapper {


    @media screen and (max-width:768px) {
      margin-bottom: 32px;
    }
  }

  .footerWrapper {
    width: 100%;
    position: absolute;
    z-index: 1300;
    margin-top: -110px;
    margin-bottom: 48px;


    @media screen and (max-width:768px) {
      margin-top: -45px;
      margin-bottom: 0;
      bottom: 16px;
    }
  }
}

.msgBox{
  position: fixed;
  width: 80%;
  padding: 20px 25px;
  background: rgba(255, 255, 255, 0.2);
  // border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(5px);
  // border: 1px solid rgba(255, 255, 255, 0.3);
  top: 100px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  line-height: 50px;
  color: #fff;
  font-size: 25px;
  z-index: 9999;
  // opacity: 0;
  overflow: hidden;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  @media screen and (max-width:768px) {
    font-size: 12px;
    line-height: 23px;
    height: auto;

  }
  @media screen and (max-width:1280px) {
    font-size: 15px;
    line-height: 23px;
    height: auto;
  }
}
.msgContent{
  position: relative;
}
.msgBar{
  margin-top: 20px;
  text-align: end;
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.msgBtn{

  padding: 10px;
  background-color: #0000003c;
  color: #fff;
  border:1px solid #fff;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  // display: inline-block;
}
.msgBox.visible {
  // opacity: 1;
  animation-name: slideDown;
  @media screen and (max-width:768px) {
    animation-name: slideDownS;

  }
  @media screen and (max-width:1280px) {
    animation-name: slideDownS;
  }
}
.msgBox.hidden {
  // opacity: 1;
  animation-name: slideUp;
  @media screen and (max-width:768px) {
    animation-name: slideUpS;

  }
  @media screen and (max-width:1280px) {
    animation-name: slideUpS;
  }
}
.msgIcon{
  position: relative;
  z-index: 9999999;
  // padding: 5px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #FFF;
  // width: 20px;
  // height: 20px;·
  z-index: 999;
  color: #FFF;
  cursor: pointer;
  width: 300px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  left: 0;
  right: 0;
  // bottom: 0;
  top: 70px;
  margin: auto;
  font-size: 16px;
  @media screen and (max-width:750px) {
    top: 30px;
  }
}
.msgIcon:hover{
  background-color: rgba(255, 255, 255, 0.416);
} 


.msgTitle{
  margin-bottom: 20px;
}

@keyframes slideDownS {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 400px; /* 或者你想要的展开高度 */
    opacity: 1;
  }
}

@keyframes slideUpS {
  from {
    height: 400px; /* 或者你想要的展开高度 */
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}
@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 400px; /* 或者你想要的展开高度 */
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    height: 400px; /* 或者你想要的展开高度 */
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}
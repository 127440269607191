.Partner {
  margin-top: 105px;
  // padding-left: 24px;
  color: #fff;



  @media screen and (max-width:768px) {

    padding-left: 14px;

  }


  .title,.row1,.desc{
    margin-bottom: 36px;

    @media screen and (max-width:1280px) {
      margin-bottom: 3rem;
    }

    @media screen and (max-width:768px) {
      margin-bottom: 28px;
    }
  }

  .title{
    text-align: center;
    font-size: 32px;
    font-family: 'GT-Walsheim-Bold-Trial';

    @media screen and (max-width:1280px) {
      margin-bottom: 2.67rem;
    }
    @media screen and (max-width:768px) {
      font-size: 16px;
    }
  }



  .row1{
    display: flex;
    align-items: center;
    justify-content: center;
    &>img{
      height: 32px;
      width: auto;

      @media screen and (max-width:1280px) {
        height: 2.66rem;
      }

      @media screen and (max-width:768px) {
        height: 17px;
      }
    }

    &>img:not(:last-of-type){
      margin-right: 48px;

      @media screen and (max-width:1280px) {
        margin-right: 4rem;
      }

      @media screen and (max-width:768px) {
        margin-right: 16px;
      }
    }
  }

  .desc{
    font-size: 12px;
    font-family: 'GT-Walsheim-Regular-Trial';
    line-height: 24px;
    color: #B8B8B8;
    white-space: pre-line;
    text-align: center;

    @media screen and (max-width:768px) {
      margin-right: 16px;

      transform: scale(0.83,0.83);
      transform-origin:center;
      -webkit-transform: scale(0.83,0.83);
      -moz-transform: scale(0.83,0.83);
      -ms-transform: scale(0.83,0.83);
      -o-transform: scale(0.83,0.83);
    }
  }

  .box{
    // width: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &>img{
      height: 40px;
      width: auto;
      margin-right: 48px;
      margin-bottom: 48px;

      @media screen and (max-width:1280px) {
        height: 3.34rem;
      }

      @media screen and (max-width:768px) {
        height: 18px;
        margin-right: 16px;
        margin-bottom: 28px;
      }
    }
  }
}

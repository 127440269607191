.StarProtocolIntroducetion {
  margin-top: 170px;
  color: #fff;


  @media screen and (max-width: 1280px) and (min-width: 768px) {
    margin-top: 14.1rem;
  }

  @media screen and (max-width:768px) {
    margin-top: 54px;
  }

  .title {
    text-align: center;
    font-size: 22px;
    font-family: 'SFUIText-Bold';
    margin-bottom: 72px;

    @media screen and (max-width: 1280px) {
      margin-bottom: 6rem;
    }


    @media screen and (max-width:768px) {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  .cardWrapper {
    display: flex;
    justify-content: center;
    position: relative;


    @media screen and (max-width:768px) {
      flex-direction: column;
      align-items: center;

    }

    .cardBox {
      width: 603px;
      height: 373px;
      flex-shrink: 0;
      color: #fff;
      position: relative;
      margin-right: 24px;


      @media screen and (max-width: 1280px) {
        width: 51rem;
        height: 31.1rem;
        margin-right: -7rem;
      }

      @media screen and (max-width:768px) {
        width: 332px;
        height: 300px;
        margin-right: 0;
      }

      .cardTitle {
        position: absolute;
        top: 40px;
        left: 36px;
        font-size: 16px;
        font-family: 'GT-Walsheim-Bold-Trial';
        z-index: 10;

        @media screen and (max-width: 1280px) {
          top: 3.3rem;
          left: 3rem;
          font-size: 1.3rem;
        }

        @media screen and (max-width:768px) {
          font-size: 14px;
          top: 32px;
          left: 36px;
        }

      }

      .desc {
        position: absolute;
        left: 36px;
        top: 78px;
        font-size: 16px;
        line-height: 32px;
        font-family: 'GT-Walsheim-Regular-Trial';
        z-index: 10;
        white-space: pre-wrap;

        @media screen and (max-width: 1280px) {
          top: 6.5rem;
          left: 3rem;
          font-size: 1.3rem;
          line-height: 2.67rem;
        }

        @media screen and (max-width:768px) {
          font-size: 14px;
          line-height: 32px;
          left: 36px;
          top: 78px;
        }

      }

      .video {

        width: 100%;
        height: auto;
        border-radius: 25px;

      }

      video {
        position: absolute;
        left: 0;
        width: 100%;
        right: 0;
        bottom: 0;
        height: 100%;
        z-index: 8;
        border: 1px solid #fff;
        @media screen and (max-width: 1280px) {
          width: 42rem;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .cardBox2 {
      width: 333px;
      height: 373px;
      position: relative;
      // border-radius: 8px;
      border: 1px solid #FFF;
      background: rgba(213, 220, 239, 0.05);


      @media screen and (max-width: 1280px) {
        width: 27.75rem;
        height: 31.1rem;
      }

      @media screen and (max-width:768px) {
        width: 332px;
        height: 300px;
        margin-top: 30px;
      }



      .cardTitle {
        margin-top: 40px;
        margin-left: 36px;
        font-size: 16px;
        font-family: 'GT-Walsheim-Bold-Trial';
        z-index: 10;


        @media screen and (max-width: 1280px) {
          margin-top: 3.3rem;
          margin-left: 3rem;
          font-size: 1.3rem;
        }

        @media screen and (max-width:768px) {
          font-size: 14px;
          margin-top: 32px;
          margin-left: 36px;
        }

      }

      .desc {
        margin-top: 16px;
        margin-left: 36px;
        font-size: 16px;
        line-height: 32px;
        font-family: 'GT-Walsheim-Regular-Trial';
        z-index: 10;
        white-space: pre-wrap;


        @media screen and (max-width: 1280px) {
          margin-top: 1.3rem;
          margin-left: 3rem;
          font-size: 1.3rem;
          line-height: 2.67rem;
        }

        @media screen and (max-width:768px) {
          font-size: 14px;
          line-height: 24px;
          margin-top: 16px;
          margin-left: 36px;
        }

      }

      .launchYourDomain {
        display: none;
        position: absolute;
        bottom: 15px;
        left: 50%;

        width: 420px;
        height: auto;
        transform: translate(-50%);
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -ms-transform: translate(-50%);
        -o-transform: translate(-50%);


        @media screen and (max-width: 1280px) {
          width: 35rem;

        }

        @media screen and (max-width:768px) {
          width: 300px;
          height: auto;
          flex-shrink: 0;
          // right: 44px;
          bottom: 10px;

        }


      }

      .show {
        display: block;
      }

      // .btnWrapper{
      //   position: absolute;
      //   bottom: 48px;
      //   right: 85px;
      //   width: 138px;
      //   height: 32px;
      //   padding: 2px;
      //   background: #C080FE;
      //   filter: blur(40px);
      //   //opacity: 0.8;
      //   border-radius: 12px;
      //   //-webkit-filter: blur(100px);
      //   -webkit-filter: blur(40px);
      //   z-index: 9;

      //   @media screen and (max-width: 1280px){
      //     bottom: 1.3rem;
      //     right: 7.1rem;
      //     width: 11.5rem;
      //     height: 2.67rem;
      //    }

      //    @media screen and (max-width:1100px) {
      //     width: 146px;
      //     height: 27px;

      //   }
      // }

      .btnWrapper {
        position: absolute;
        bottom: 28px;
        width: 203px;
        height: 45px;
        flex-shrink: 0;
        border-radius: 16px;
        background: linear-gradient(180deg, #8043BA -39.06%, #382D93 103.12%);
        filter: blur(5.342093467712402px);
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);

        @media screen and (max-width: 1280px) {
          bottom: 2.3rem;

          width: 16.9rem;
          height: 3.75rem;
        }

        @media screen and (max-width:768px) {
          width: 152px;
          height: 32px;
          bottom: 20px;
        }
      }

      .btn,
      .btnCover {
        position: absolute;
        bottom: 28px;
        display: flex;
        width: 190px;
        height: 40px;
        padding: 10px 21px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 16px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);

        border-top: 2px solid #C080FE;
        // background: #000;
        box-shadow: 0px 5.342px 5.342px 0px rgba(255, 255, 255, 0.25) inset;


        @media screen and (max-width: 1280px) {
          bottom: 2.3rem;
          //right: 7.58rem;
          width: 15.8rem;
          height: 3.3rem;
          border-radius: 1.3rem;
          -webkit-border-radius: 1.3rem;
          -moz-border-radius: 1.3rem;
          -ms-border-radius: 1.3rem;
          -o-border-radius: 1.3rem;
        }

        @media screen and (max-width:768px) {
          width: 146px;
          height: 30px;
          bottom: 18px;
          border-radius: 12px;
          -webkit-border-radius: 12px;
          -moz-border-radius: 12px;
          -ms-border-radius: 12px;
          -o-border-radius: 12px;
        }




        .text {

          white-space: nowrap;
          background: linear-gradient(178deg, #FFF 36.12%, rgba(0, 0, 0, 0.00) 240.82%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;


          font-family: 'GT-Walsheim-Regular-Trial';
          font-size: 16px;

          @media screen and (max-width: 1280px) {
            font-size: 1.3rem;
          }

          @media screen and (max-width: 768px) {

            font-size: 12px;
            // transform: scale(0.83, 0.83);
            // transform-origin: center;
            // -webkit-transform: scale(0.83, 0.83);
            // -moz-transform: scale(0.83, 0.83);
            // -ms-transform: scale(0.83, 0.83);
            // -o-transform: scale(0.83, 0.83);
          }
        }
      }

      // .btn,.btnCover {
      //   position: absolute;
      //   bottom: 32px;
      //   // right: 91px;
      //   width: 141px;
      //   height: 30px;
      //   border-radius: 12px;
      //   background: #000;
      //   border: none;
      //   box-shadow: 0px 2px 2px 0px #af8adf inset;
      //   font-family: 'GT-Walsheim-Regular-Trial';
      //   font-size: 12px;
      //   color: #fff;
      //   background-color: #000;

      //   cursor: pointer;

      //   left: 50%;
      //   transform: translate(-50%);
      //   -webkit-transform: translate(-50%);
      //   -moz-transform: translate(-50%);
      //   -ms-transform: translate(-50%);
      //   -o-transform: translate(-50%);

      //   z-index: 80;


      //   @media screen and (max-width: 1280px){
      //     bottom: 1.08rem;
      //    // right: 7.58rem;
      //     width: 11.75rem;
      //     height: 2.5rem;
      //    }

      //    @media screen and (max-width:1100px) {
      //     width: 146px;
      //     height: 27px;
      //    // right: 55px;
      //     bottom: 18px;
      //     // right: 91px;
      //     // bottom: 16px;

      //     // font-size: 12px;
      //     // transform: scale(0.83,0.83);
      //     // transform-origin:0 ;
      //     // -webkit-transform: scale(0.83,0.83);
      //     // -moz-transform: scale(0.83,0.83);
      //     // -ms-transform: scale(0.83,0.83);
      //     // -o-transform: scale(0.83,0.83);


      //    }

      //    @media screen and (max-width:768px) {

      //     font-size: 12px;
      //     transform: scale(0.83,0.83);
      //     transform-origin:0 ;
      //     -webkit-transform: scale(0.83,0.83);
      //     -moz-transform: scale(0.83,0.83);
      //     -ms-transform: scale(0.83,0.83);
      //     -o-transform: scale(0.83,0.83);
      //    left: 105px;
      //    }
      // }

      .btnCover {
        z-index: 100;
        border: none;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}
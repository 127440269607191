.Footer {
  width: 100%;


  .box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 0 76px;
    height: 64px;

    @media screen and (max-width:768px) {
      align-items: flex-end;
      padding: 0 29px;
    }

    @media screen and (max-width:380px) {
      align-items: flex-end;
      padding: 0 16px;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .themeLogo{
        width: 100px;
        height: auto;

        @media screen and (max-width:1280px) {
          width: 8.3rem;
        }

        @media screen and (max-width:768px) {
          width: 54px;
        }
      }

      .text {
        font-family: 'GT-Walsheim-Regular-Trial';
        margin-top: 16px;
        font-size: 16px;

        @media screen and (max-width:1280px) {
          margin-top: 1.3rem;
          font-size: 1.3rem;
        }
        @media screen and (max-width:768px) {
          margin-top: 16px;
          font-size: 12px;
          line-height: 14px;
        }
      }

    }
    .right {

      @media screen and (max-width:768px){
        margin-bottom: -5px;
      }

      img{
        width: 24px;
        height: 24px;
        cursor: pointer;

        @media screen and (max-width:1280px) {
          width: 2rem;
          height: 2rem;
        }

        @media screen and (max-width:768px) {
          width: 18px ;
          height: 18px;
        }
      }

      &>a:not(:last-of-type){
        margin-right: 16px;

        @media screen and (max-width:768px) {
          margin-right: 8px;
        }
      }
    }
  }
}

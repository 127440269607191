.Header {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 70px;
  z-index: 90;
  // background-color: #000;

  @media screen and (max-width:768px) {
    height: 56px;

  }


  .inner{

    margin: 0 auto;
    width: 1280px;
    height: 70px;
    padding: 0 76px;
   // display: flex;
    align-items: center;
    justify-content: space-between;

    display: none;


    @media screen and (max-width: 1280px) {
      width:100%;
    }

    @media screen and (max-width:768px) {
      padding: 0 28px;
      height: 56px;
      display: flex;
    }



      &>img{
        width: 100px;
        height: 30px;

        @media screen and (max-width:768px) {
          display: none;
        }
      }

    .right{
      display: flex;
      align-items: center;

      @media screen and (max-width:768px) {
        display: none;
      }

      &>a{
        font-size: 16px;
        font-family: 'GT-Walsheim-Regular-Trial';
        font-weight: normal;
        color: #FFFFFF;
        line-height: 19px;
        text-decoration: none;
        pointer-events: none;
      }

      &>a:not(:last-of-type){
        margin-right: 24px;
      }
    }




    .bread{
      width: 24px;
      height: 24px;
      display: none;
      @media screen and (max-width:768px) {
        display: block;
      }
    }
  }
}
.Milestones {
  margin-top: 191px;

  @media screen and (max-width:1280px){
    margin-top: 16rem;
  }

  @media screen and (max-width:768px) {
    margin-top: 54px;

  }

  .title {
    width: fit-content;
    margin: 0 auto;
    padding: 7px 22px;
    // border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.10);
    margin-bottom: 35px;
    color: #FFF;
    font-family: 'SFUIText-Bold';
    font-size: 22px;

    @media screen and (max-width:1280px) {
      margin-bottom: 2.92rem;
      font-size: 1.84rem;
    }
    @media screen and (max-width:768px){
      padding: 7px 22px;
      border-radius: 8px;
      font-size: 16px;
      margin-bottom: 26px;
    }
  }

  .carWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    @media screen and (max-width:1280px) {
      margin-bottom: 2.5rem;
    }

    @media screen and (max-width:768px){
      margin-bottom: 16px;
    }


    .cardBox {
      padding: 32px;
      // border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.08) 100%);
      backdrop-filter: blur(16.25px);
      color: #FFF;
      position: relative;



      @media screen and (max-width:1280px)  {
        padding: 2.7rem;
      }

      @media screen and (max-width:768px) {
        padding: 16px;

      }


      nav {
        font-size: 22px;
        font-family: 'GT-Walsheim-Regular-Trial';
        white-space: pre-wrap;
        text-align: left;
        line-height: 33px;

        @media screen and (max-width:1280px) {
          font-size: 1.82rem;
        }

        @media screen and (max-width:768px) {
          font-size: 14px;
          line-height: 21px;
        }
      }

      footer {
        position: absolute;
        bottom: 32px;
        right: 32px;
        left: 32px;
        height: 69px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;


        @media screen and (max-width:1280px) {
          bottom: 2.6rem;
          right: 2.6rem;
          left: 2.6rem;
          height: 5.75rem;
        }

        @media screen and (max-width:768px) {
          height: 36px;
          bottom: 16px;
          left: 16px;
          right: 16px;
        }

        .left {
          font-size: 46px;
          font-family: 'GT-Walsheim-Bold-Trial';

          @media screen and (max-width:1280px){
            font-size: 3.83rem;
          }

          @media screen and (max-width:768px) {
            font-size: 24px;
          }

        }

        .right {
          width: 118px;
          height: 37px;
          border-radius: 8px;
          border: 1px solid #FFF;
          font-size: 24px;
          font-family: 'GT-Walsheim-Regular-Trial';
          background: transparent;
          color: #FFF;

          @media screen and (max-width:1280px) {
            width: 9.84rem;
            height: 3.1rem;
            font-size: 2rem;
          }

          @media screen and (max-width:768px) {
            width: 69px;
            height: 18px;
            font-size: 12px;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
}
        }

        .planet {
          display: block;
          width: 60px;
          height: 60px;

          @media screen and (max-width:1280px) {
            width: 5rem;
            height: 5rem;
          }

          @media screen and (max-width:768px) {
            display: none;
          }
        }
      }
    }

    .domainExtensionBox {
      width: 279px;
      height: 257px;

      margin-right: 30px;

      @media screen and (max-width:1280px) {
        width: 23.3rem;
        height: 21.4rem;
        margin-right: 2.5rem;
      }

      @media screen and (max-width:768px) {
        width: calc(50% - 27px - 7px);
        height: 10.1rem;
        margin-right: 15px;
        min-height: 120px;
      }

    }

    .peggistrationBox {
      width: 478px;
      height: 257px;
      margin-right: 30px;

      @media screen and (max-width:1280px) {
        width: 39.8rem;
        height: 21.4rem;

        margin-right: 2.5rem;
      }

      @media screen and (max-width:768px) {
        width: calc(50% - 27px - 7px);
         height: 10.1rem;
        margin-right: 0;
        min-height: 120px;
      }
    }

    .walletBox {
      width: 310px;
      height: 257px;

      @media screen and (max-width:1280px) {
        width: 25.8rem;
        height: 21.4rem;
      }

      @media screen and (max-width:768px) {
        width: calc(50% - 27px - 7px);
         height: 10.1rem;
        margin-right: 15px;
        min-height: 120px;
      }
    }

    .networkBox {
      width: 671px;
      height: 260px;
      display: flex;
      margin-right: 30px;

      overflow: hidden;

      @media screen and (max-width:1280px) {
        width: 55.9rem;
        height: 21.6rem;
        margin-right: 2.5rem;
      }

      @media screen and (max-width:768px) {
       padding: 16px 16px 10px 16px;
       width: calc(100% - 54px);
       height: auto;
        margin-right: 0;
      }

      @media screen and (max-width:753px) {
        // width: calc(100% - 54px);
        // margin-right: 0;
        //  height: 10.1rem;
        // height: 10.1rem;
        // min-height: 120px;
      }

      @media screen and (max-width:520px) {
        height: fit-content;

      }



      .left {
        margin-right: 38px;

        @media screen and (max-width:1280px) {
          margin-right: 3.16rem;
        }
        @media screen and (max-width:768px) {
          margin-right: 110px;
        }
        @media screen and (max-width:763px) {
          margin-right: 20px;
        }

      }

      .rightBox{
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }

      .right {
        display: grid;
        grid-template-columns: repeat(9, 32px);
        grid-template-rows: repeat(3, 32px);
        grid-row-gap: 22px;
        grid-column-gap: 20px;
        justify-content: flex-end;

        @media screen and (max-width:1280px) {
          grid-template-columns: repeat(9, 2.6rem);
          grid-template-rows: repeat(3, 2.6rem);
          grid-row-gap: 1.83rem;
          grid-column-gap: 1.6rem;
        }

        @media screen and (max-width:768px) {
          width: 376px;
          display: flex !important;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 0;

          img{
            margin-left: 12px !important;
            margin-bottom: 16px !important;
          }
          // display: flex;
          // flex-wrap: wrap;
          // row-gap: 14px;
          // column-gap: 12px;
          // justify-content: flex-start;
          // grid-template-columns: repeat(7, 22px);
          // grid-template-rows: repeat(7, 22px);
          // grid-row-gap: 1rem;
          // grid-column-gap: 1rem;
        }

        @media screen and (max-width:565px){
          width: 274px;
        }

        @media screen and (max-width:465px){
          width: 204px;
        }

        @media screen and (max-width:395px){
          width: 172px;
        }

        @media screen and (max-width:365px){
          width: 138px;
        }



        // @media screen and (max-width:753px) {
        //   display: grid !important;
        //   grid-template-columns: repeat(7, 1.83rem);
        //   grid-template-rows: repeat(4, 1.83rem);
        //   grid-row-gap: 0.4rem;
        //   grid-column-gap: 0.4rem;

        //   img{
        //     margin-left: 0px !important;
        //     margin-bottom: 0px !important;
        //   }
        // }

        // @media screen and (max-width:520px) {
        //   display: grid;
        //   grid-template-columns: repeat(7, 22px);
        //   grid-template-rows: repeat(4, 22px);
        //   grid-row-gap: 12px;
        //   grid-column-gap: 12px;
        // }

        // @media screen and (max-width:385px) {

        //   grid-template-columns: repeat(6, 22px);
        //   grid-template-rows: repeat(6, 22px);
        //   grid-row-gap: 12px;
        //   grid-column-gap: 12px;
        // }
        // @media screen and (max-width:360px) {
        //   grid-template-columns: repeat(5, 22px);
        //   grid-template-rows: repeat(5, 22px);
        //   grid-row-gap: 12px;
        //   grid-column-gap: 12px;
        // }

        img {
          width: 32px;
          height: 32px;

          // margin-right: 20px;
          // margin-bottom: 20px;

          @media screen and (max-width:1280px) {
            width: 2.6rem;
            height: 2.6rem;

            // margin-bottom: 1.6rem;
            // margin-right: 1.6rem;
          }

          @media screen and (max-width:768px) {
            width: 22px;
            height: 22px;
            margin: 0;

          }
          // @media screen and (max-width:753px) {
          //   width: 1.53rem;
          //   height: 1.53rem;
          // }

          // @media screen and (max-width:520px) {
          //   width: 22px;
          //   height: 22px;
          // }
        }

      }
    }

    .partnersBox {
      width: 427px;
      height: 260px;

      @media screen and (max-width:1280px) {
        width: 35.58rem;
        height: 21.6rem;
      }

      @media screen and (max-width:768px) {
        width: 100%;
        height: 100%;
        min-height: 120px;
      }

      .right {
        .monkey {
          display: block;
          position: absolute;
          top: 32px;
          right: 32px;
          width: 168px;
          height: 120px;

          @media screen and (max-width:1280px) {
            top: 2.6rem;
            right: 2.6rem;
            width: 14rem;
            height: 10rem;
          }

          @media screen and (max-width:768px) {
           display: none;
          }
        }

      }
    }

  }


  .explore,.cover {
    position: absolute;
    right: 32px;
    bottom: 32px;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(255, 255, 255, 0.20);
    font-size: 16px;
    font-family: 'GT-Walsheim-Bold-Trial';
    color: #FFF;

    @media screen and (max-width:1280px) {
      right: 2.6rem;
      bottom: 2.6rem;

      padding: 0.67rem 1.33rem;

      font-size: 1.3rem;
    }

    @media screen and (max-width:768px) {
      width: 71px;
      height: 28px;
      // line-height: 28px;
      text-align: center;
     padding:0;
     right: 16px;
     bottom: 16px;
     font-size: 14px;
     font-family: 'GT-Walsheim-Medium-Trial';
     border-radius: 100px;
     -webkit-border-radius: 100px;
     -moz-border-radius: 100px;
     -ms-border-radius: 100px;
     -o-border-radius: 100px;
    }
  }

  .coverBox {
    position: relative;

    @media screen and (max-width:768px) {
      width: calc(50% - 29px - 7px);
      height: 10.1rem;
      min-height: 120px;
    }
  }

  .cover{
    position: absolute;
    z-index: 1300;
    color: transparent;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.row{
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  span{
    margin-top: 2px;
    font-size: 12px;
    font-family: 'GT-Walsheim-Regular-Trial';

    font-size: 12px;
    transform: scale(0.83,0.83);
    transform-origin:center ;
    -webkit-transform: scale(0.83,0.83);
    -moz-transform: scale(0.83,0.83);
    -ms-transform: scale(0.83,0.83);
    -o-transform: scale(0.83,0.83);


    @media screen and (max-width:1000px) {
      margin-top: 0;
      transform: scale(0.66,0.66);
      -webkit-transform: scale(0.66,0.66);
      -moz-transform: scale(0.66,0.66);
      -ms-transform: scale(0.66,0.66);
      -o-transform: scale(0.66,0.66);
    }

    @media screen and (max-width:768px) {
      position: absolute;
      bottom: 2px;
      text-align: center;
      display:block;
      width: 22px !important;
    }

    // @media screen and (max-width:753px) {
    //   position: static;
    //   display: inline;

    // }
  }
}
.minChianText{
  display: inline-block;
  @media screen and (max-width:768px){
    padding-left: 13px;
  }

  // @media screen and (max-width:753px) {
  //  padding-left: 0;

  // }
}
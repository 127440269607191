.DigitalRealms {
  margin-top: 64px;

  @media screen and (max-width:768px) {
    margin-top: 26px;

  }

  .box{
    position: relative;
    color: #fff;
    padding-bottom: 100px;
    .title{
      font-size: 64px;
      line-height: 75px;
      font-family: 'GT-Walsheim-Regular-Trial';
      white-space: pre-wrap;
      text-align: center;

      @media screen and (max-width:1280px) {
        font-size: 5.3rem;
        line-height: 6.25rem;
      }

      @media screen and (max-width:768px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
    .desc{
      margin-top: 30px;
      font-size: 24px;
      line-height: 28px;
      font-family: 'GT-Walsheim-Regular-Trial';
      text-align: center;
      white-space: pre-wrap;

      @media screen and (max-width:1280px) {
        font-size: 2rem;
        margin-top: 2.5ren;
        line-height: 2.3rem;
      }

      @media screen and (max-width:768px) {
        margin-top: 20px;
        font-size: 14px;
        line-height: 28px;
      }
    }

    .light {
      width: 100%;
      margin-top: -500px;

      @media screen and (max-width:1280px) {
       margin-top: -41.6rem;
      }

      @media screen and (max-width:768px) {
        margin-top: -240px;
       }

       @media screen and (max-width:618px) {
        margin-top: -200px;
       }

       @media screen and (max-width:490px) {
        margin-top: -160px;
       }
    }
  }
}

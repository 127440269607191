*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a{
  text-decoration: none;
}
html,body{
  font-family: 'GT-Walsheim-Regular-Trial';
}
@font-face {
  font-family: 'GT Walsheim Trial';
  src: url('./assets/fonts/GT-Walsheim-Condensed-Regular-Trial.otf');
}

@font-face {
  font-family: 'GT-Walsheim-Bold-Trial';
  src: url('./assets/fonts/GT-Walsheim-Bold-Trial.otf');
}

@font-face {
  font-family: 'GT-Walsheim-Light-Trial';
  src: url('./assets/fonts/GT-Walsheim-Light-Trial.otf');
}

@font-face {
  font-family: 'GT-Walsheim-Medium-Trial';
  src: url('./assets/fonts/GT-Walsheim-Medium-Trial.otf');
}

@font-face {
  font-family: 'GT-Walsheim-Regular-Trial';
  src: url('./assets/fonts/GT-Walsheim-Regular-Trial.otf');
}

@font-face {
  font-family: 'SFUIText-Bold';
  src: url('./assets/fonts/SFUIText-Bold.ttf');
}

@font-face {
  font-family: 'HvDTrial_Brandon_Grotesque_bold.otf';
  src:url('./assets/fonts/HvDTrial_Brandon_Grotesque_bold.otf')
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'GT Walsheim Trial-Th';
  src: url('./assets/fonts/GT-Walsheim-Thin-Trial.otf');
}

.myFluidBg{
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  background-color: transparent;
  /* opacity: 0.9; */
}
.c-kEkIHu{
  display: none;
}
canvas{
  pointer-events: 'none'!important;
  background: transparent !important;
}
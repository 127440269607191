.btns button {
  padding: 0 14px;
  height: 40px;
  border: 1px solid transparent;
  background-color: transparent;
  color: #fff;
  opacity: 0.9;
  cursor: pointer;
  font-family: 'GT-Walsheim-Regular-Trial';


}

.walletBtn {
  /* line-height: 45px; */
  height: 40px;
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #eee;
  background: transparent;
  color: #fff;
  cursor: pointer;
}

.btns button:not(:last-of-type) {
  margin-right: 3px;
}

.btns button:hover {
  color: #ffffff !important;
}

.btns button.active {
  border: 1px solid #4B4B4B;
  border-radius: 11px;

  color: #ffffff !important;
}

.btns {
  display: flex;
  justify-content: center;
  flex-basis: 1;
  flex-wrap: wrap;

  @media screen and (max-width:750px) {
    width: 340px;
    display: flex;
    display: -webkit-flex;
    display: -moz-box;
    display: -webkit-box;
    display: -ms-flexbox;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;
  }
}

.btns button {
  padding: 0 11px;
}

.btns button:not(:last-of-type) {
  margin-right: 1px;
}


.page2 {
  box-sizing: border-box;
}

@media screen and (min-width:750px) {

  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F4F4F4;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(121, 121, 121, 0.4);
  }
}

.PartnerWrapper {
  /* 264+88 */
  margin-bottom: 352px;

  .submit {
    height: 40px;
  }


}

.videoNft {
  filter: blur(10px) grayscale(100%);
  position: fixed;
  width: 900px;
  height: 500px;
  z-index: -1;
  /* 或者移除这一行 */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.4;
  border-bottom: 1px solid #fff;

  @media screen and (max-width:750px) {
    width: 400px;
    height: 200px;
    top: -200px;
  }
}

.btnWrapper {

  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  /* width:100%;
  display:flex;
  justify-content:center;
  position: absolute;
  bottom: -88px;
  clear: both;
  z-index: 1000; */
  /*
  @media screen and (max-width:1280px) {
    bottom:-18.3rem;

  }
  @media screen and (max-width:768px) {
    bottom:-158px;

  } */
}

.footerBox {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 650px;
  /* background: radial-gradient(37.62% 51.62% at 24.59% 100%, #F00 0%, rgba(102, 0, 255, 0.00) 100%), radial-gradient(44.8% 50.53% at 11.91% 46.14%, transparent 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(86.39% 78.28% at 97.16% 105.97%, #90F 0%, rgba(153, 0, 255, 0.00) 100%), radial-gradient(22.72% 111.23% at 58.81% 104.81%, #60F 14.5%, rgba(102, 0, 255, 0.00) 100%), transparent; */
  display: flex;
  justify-self: space-between;
  align-items: flex-end;
  padding-bottom: 16px;

  .footerPicture {
    display: none;
    width: 100%;
    height: auto;
    opacity: 1;
  }
}

.stepCon {
  width: '700px';
  border-radius: '3px';
  height: '450px';
  color: 'white';
  display: 'flex';
  left: '0';
  position: 'fixed';
  top: '0';
  right: '0';
  bottom: '0';
  z-index: '9999';
  margin: 'auto'
}

.imgStep {
  height: 100px;
  margin-left: 50px;
  filter: blur(0px) grayscale(100%);
}

.imgBar {
  top: 570px;
  border-top: 1px solid #fff;
  background-color: #00001a8e;
  padding: 10px;
  position: fixed !important;
  transform: rotate(45deg);
}

.btnSteps {
  height: 40px;
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #eee;
  background: transparent;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 10px;
}

.btnSteps:disabled {
  background: rgba(81, 81, 81, 0.391);
  border-color: #888888;
}

.stepDiv {
  /* position: fixed; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  /* 在主轴上居中 */
  align-items: center;
  /* 在交叉轴上居中 */
  width: 100vw;
  height: 300px;

  @media screen and (max-width:750px) {
    width: 50%;

  }
}

.titleStep {
  width: 100%;
  text-align: center;
  /* margin-top: -30px; */
  opacity: 0;
  height: 900px;
}

.titleStepTrue {
  top: -30px;
  position: fixed;
  left: 0;
  width: 100%;
  text-align: center;

  @media screen and (max-width:750px) {
    font-size: 20px;
    top: 20px;
  }
}

.fakeText {
  /* position: fixed; */
  color: #fff;
  font-size: 65px;
  top: 300px;
  opacity: 0;
}

.fakeTextTrue {
  position: fixed;
  color: #fff;
  font-size: 65px;
  top: 300px;

  @media screen and (max-width:750px) {
    font-size: 50px;
  }
}

.btnBar {

  /* padding-top: 55%; */
  @media screen and (max-width:750px) {
    width: 500px;
  }
}

.stepText {
  line-height: 55px;
}

.tabHeader {
  display: flex;
  width: 100%;
  height: 30px;
}

.tabHead {
  display: block;
  width: 100%;
  /* opacity: 0; */
}

.tabActive {
  position: relative;
}

.tabActive::after {
  position: absolute;
  content: '☑️';
  width: 20px;
  height: 20px;
  line-height: 30px;
  font-size: 20px;
  margin-left: 5px;

  @media screen and (max-width:750px) {
    font-size: 15px;
  }
}

.menuSteps {
  width: '200px';
  position: relative
}

.menuContent {
  width: 900px;
  border-radius: 3px;
  height: 450px;
  color: white;
  display: flex;
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: auto;

  @media screen and (max-width:750px) {
    width: 500px;
  }
}

.menuRow {
  padding: 15px 25px;
  flex: 1;

  @media screen and (max-width:750px) {
    width: 300px;
  }
}

.lineStep {
  /* display: block; */
  display: none;
  width: 2px;
  position: absolute;
  left: 200px;
  top: 0;
  color: #fff;
  border-radius: 1px solid white;
  z-index: 200;
  height: 200%
}

.tab {
  flex: 1;
  border-bottom: 1px solid #fff;
  font-size: 25px;
  padding-bottom: 10px;
  cursor: pointer;
}

.tabs {
  width: 900px;
  border-radius: 3px;
  height: 450px;
  color: white;
  display: block;
  inset: 0px;
  position: fixed;
  z-index: 10000;
  margin: auto;
  top: -87px;

  @media screen and (max-width:750px) {
    width: 370px;

  }
}

.tabContent {
  display: block;
}

.countBox {
  font-size: 55px;
  display: block;
  text-align: center;
  padding-top: 100px;
}